import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Logout from '@/views/Logout.vue';
import PageNotFound from '@/components/PageNotFound.vue';
import AccountLayout from '@/components/account/AccountLayout.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/create-account',
      name: 'ConfigAccount',
      component: AccountLayout,
      children: [
        {
          path: '',
          name: 'CreateAccount',
          component: () => import('@/views/account/CreateAccount.vue'),
        },
        {
          path: 'confirm',
          name: 'EmailSend',
          component: () => import('@/views/account/AccountEmailSend.vue'),
        },
        {
          path: 'verify',
          name: 'Verify',
          component: () => import('@/views/account/AccountVerify.vue'),
        },
        {
          path: 'space',
          name: 'ConfigSpace',
          component: () => import('@/views/account/AccountSpace.vue'),
        },
        {
          path: 'payment',
          name: 'ConfigPayment',
          component: () => import('@/views/account/AccountPayment.vue'),
        },
        {
          path: 'paid',
          name: 'ConfigPaid',
          component: () => import('@/views/account/AccountPaid.vue'),
        },
        {
          path: 'billing',
          name: 'ConfigBilling',
          component: () => import('@/views/account/AccountBilling.vue'),
        },
        {
          path: 'invite',
          name: 'ConfigInvite',
          component: () => import('@/views/account/AccountInvite.vue'),
        },
        {
          path: 'invited',
          name: 'AccountInvited',
          component: () => import('@/views/account/AccountInvited.vue'),
        },
        {
          path: 'invoiced',
          name: 'AccountInvoiced',
          component: () => import('@/views/account/AccountInvoiced.vue'),
        },
        {
          path: 'terms',
          name: 'AccountTerms',
          component: () => import('@/views/account/AccountTerms.vue'),
        },
        {
          path: 'linked',
          name: 'AccountLinked',
          component: () => import('@/views/account/AccountLinked.vue'),
        },
      ],
    },
    {
      path: '/create-account/team',
      name: 'RegisterTeam',
      component: () => import('@/views/account/CreateAccountTeam.vue'),
    },
    {
      path: '/create-account/team/created',
      name: 'AccountCreated',
      component: () => import('@/views/account/AccountCreated.vue'),
    },
    {
      path: '/create-account/team/linked',
      name: 'AccountLinkedTeam',
      component: () => import('@/views/account/AccountLinkedTeam.vue'),
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@/views/RestorePassword.vue'),
    },
    {
      path: '/link-google',
      name: 'linkGoogleAccount',
      component: () => import('@/views/LinkGoogleAccount.vue'),
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: () => import('@/views/ChangePassword.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: PageNotFound,
    },
  ],
});

export default router;
