import { configure, defineRule } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import {
  required,
  email,
  min,
  confirmed,
  integer,
  between,
  min_value,
  is,
  max_value
} from '@vee-validate/rules';
import es from '@vee-validate/i18n/dist/locale/es.json';
import en from '@vee-validate/i18n/dist/locale/en.json';

// Install rules
defineRule('is', is);
defineRule('min', min);
defineRule('email', email);
defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('integer', integer);
defineRule('minValue', min_value);
defineRule('maxValue', max_value);
defineRule('between', between);

// Custom rules
defineRule('minUppercase', (value, [count]) => {
  const requiredUppercaseCount = parseInt(count, 10) || 1;
  const regexPattern = new RegExp(`(?:.*[A-Z]){${requiredUppercaseCount},}`);

  return regexPattern.test(value);
});

defineRule('minLowercase', (value, [count]) => {
  const requiredLowercaseCount = parseInt(count, 10) || 1;
  const regexPattern = new RegExp(`(?:.*[a-z]){${requiredLowercaseCount},}`);

  return regexPattern.test(value);
});

defineRule('minDigits', (value, [count]) => {
  const minNumberCount = parseInt(count, 10) || 1;
  const regexPattern = new RegExp(`(?:.*\\d.*){${minNumberCount},}`);

  return regexPattern.test(value);
});

defineRule('ssn', (value) => new RegExp(/^\d{3}-\d{2}-\d{4}$/).test(value));
defineRule('ein', (value) => new RegExp(/^\d{2}-\d{7}$/).test(value));
defineRule('rfc', (value) =>
  new RegExp(/^[A-Z&Ññ]{3,4}\d{6}[A-Z0-9]{3}$/).test(value)
);
defineRule('mexPostalCode', (value) =>
  new RegExp(/^\d{5}([A-Z]\d{2})?$/).test(value)
);
defineRule('usaZip', (value) => new RegExp(/^\d{5}(-\d{4})?$/).test(value));

// Install messages
configure({
  generateMessage: localize({
    en: {
      ...en,
      names: {
        name: 'name',
        email: 'email',
        password: 'password',
        passwordConfirmation: 'password confirmation',
        licenses: 'licenses',
        numberCard: 'number card',
        placeholder: 'placeholder',
        dueDate: 'due date',
        workspace: 'workspace name',
        business: 'business',
        platform_usage: 'How do you plan to use the platform?',
        tax_id_value: 'tax id value',
        organization_name: 'organization name',
        rfc: 'RFC',
      },
      messages: {
        ...en.messages,
        maxValue: en.messages.max_value,
        minValue: en.messages.min_value,
        minUppercase:
          'The {field} must contain at least 0:{count} uppercase letter(s)',
        minLowercase:
          'The {field} must contain at least 0:{count} lowercase letter(s)',
        minDigits: 'The {field} must contain at least 0:{count} digit(s).',
      },
    },
    es: {
      ...es,
      names: {
        name: 'nombre',
        email: 'correo',
        password: 'contraseña',
        passwordConfirmation: 'confirmar contraseña',
        licenses: 'licencias',
        numberCard: 'número de tarjeta',
        placeholder: 'nombre de la tarjeta',
        dueDate: 'fecha de vencimiento',
        workspace: 'nombre del espacio de trabajo',
        business: 'nombre de la empresa',
        platform_usage: '¿Cómo planeas usar la plataforma?',
        tax_id_value: 'valor del tax id',
        organization_name: 'nombre de la organización',
        rfc: 'RFC',
      },
      messages: {
        ...es.messages,
        maxValue: es.messages.max_value,
        minValue: es.messages.min_value,
        minUppercase:
          'El campo {field} debe contener al menos 0:{count} letra(s) mayúscula(s)',
        minLowercase:
          'El campo {field} debe contener al menos 0:{count} letra(s) minúscula(s)',
        minDigits: 'El campo {field} debe contener al menos 0:{count} dígitos.',
        rfc: 'El campo {field} no es válido',
      },
    },
  }),
});

setLocale('es');

export function changeLanguage(lang) {
  setLocale(lang);
}
