import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import Vue3Toastify from 'vue3-toastify';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import messages from '@intlify/unplugin-vue-i18n/messages';
import { datadogRum } from '@datadog/browser-rum';
import { vTooltip, vClosePopper } from 'floating-vue';
import moment from '@/lib/moment';
import App from './App.vue';
import router from './router';
import { changeLanguage } from './vee-validate';
import { scrollY } from '@sellia/base/directives/scroll';
const [currentLang = 'es'] = navigator.language.split('-');

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const toastOptions = {
  autoClose: 5000,
  theme: 'colored',
  pauseOnHover: true,
  clearOnUrlChange: false,
};

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(moment)
  .use(Vue3Toastify, toastOptions)
  .directive('tooltip', vTooltip)
  .directive('close-popper', vClosePopper)
  .directive('scroll-y', scrollY)
  .use(
    createI18n({
      locale: currentLang || 'es',
      fallbackLocale: 'es',
      messages,
    })
  );

changeLanguage(currentLang || 'es');

const isProduction = import.meta.env.VITE_DD_ENVIRONMENT === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATIONID,
    clientToken: import.meta.env.VITE_DD_CLIENTTOKEN,
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DD_SERVICE,
    env: import.meta.env.VITE_DD_ENVIRONMENT,
    version: import.meta.env.PACKAGE_VERSION,
    defaultPrivacyLevel: 'mask-user-input',
    forwardErrorsToLogs: true,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });
}

app.mount('#app');
