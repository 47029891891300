<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';

const store = useUserStore();
const router = useRouter();

onMounted(() => {
  store.logout().then(() => router.replace('/'));
});
</script>

<template></template>
