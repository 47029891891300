<template>
  <button
    v-tooltip="{ content: text, class: 'tooltip-custom tooltip-arrow'}"
    class="button icon xxs"
    :class="$attrs.customClass"
  >
    <Icon icon="mdi:information-variant" class="icon-md" />
  </button>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  components: { Icon },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  customClass: {
    type: [String, Object, Array],
  }
};
</script>

