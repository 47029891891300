import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_AUTH_URL;
const API = axios.create({ baseURL: `${baseUrl}/api/v1` });

export const signup = (params) => {
  return API.post('/google/signup', params);
};

export const signupTeam = (params) => {
  return API.post('/google/signupTeam', params);
};

export const signin = (params) => {
  return API.post('/google/login', params);
};

export const link = (params) => {
  return API.post('/google/link', params);
};
