<template>
  <div class="help-chat absolute right-16 bottom-32 flex flex-col gap-8 items-center">
    <button
      v-tooltip="{ content: '¿Necesitas ayuda?', disabled: isChatOpen, placement: 'left' }"
      type="button"
      class="bg-navy-500 p-16 rounded-full text-neutral-50"
      :class="{ '!bg-custom-50': isChatOpen }"
      data-test-id="startSupportButton"
      @click="isChatOpen = true"
    >
      <Icon icon="ic:twotone-support-agent" class="icon-lg" />
    </button>

    <template v-if="isChatOpen">
      <a
        role="button"
        :href="whatsAppChatUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="rounded-full p-8 bg-whatsapp-600 text-neutral-50"
        data-test-id="supportWhatsAppLink"
        @click="closeChat"
      >
        <Icon icon="mdi:whatsapp" class="icon-lg" />
      </a>
      <a
        role="button"
        :href="telegramChatUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="rounded-full p-8 bg-custom-100 text-neutral-50"
        data-test-id="supportTelegramLink"
        @click="closeChat"
      >
        <Icon icon="mdi:telegram" class="icon-lg" />
      </a>
      <button
        type="button"
        class="rounded-full p-8 bg-neutral-500 text-neutral-50"
        data-test-id="supportCloseButton"
        @click="closeChat"
      >
        <Icon icon="mdi:close" class="icon-lg" />
      </button>
    </template>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { onMounted, onUnmounted, ref } from 'vue';

const whatsAppChatUrl = import.meta.env.VITE_CHAT_HELP_WHATSAPP_URL;
const telegramChatUrl = import.meta.env.VITE_CHAT_HELP_TELEGRAM_URL;
const isChatOpen = ref(false);

const closeChat = () => {
  isChatOpen.value = false;
};

const handleOutsideClick = (e) => {
  if (!e.target.closest('.help-chat')) {
    isChatOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>
